import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../../providers/Conexao'
import md5 from 'md5';
import Swal from 'sweetalert2'

import {withRouter} from 'react-router-dom'
import Template from '../Template/Template';
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import RemoveIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PersonIcon from '@material-ui/icons/Person';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import Build from '@material-ui/icons/Build'
import IconePDF from './Componentes/pdf.png'
import IconeCSV from './Componentes/csv.png'
import { PrintOutline } from 'react-ionicons';


const GestaoRelatoriosCliente = (props)=>{
    const { idcliente, idusuario, idempresa, razaosocial} = props.location.state.cliente;
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [dataInicial, setDataInicial] = useState('')
    const [tokenAPI, setTokenAPI] = useState('');
    const [dataFinal, setDataFinal] = useState('')

    useEffect(()=>{

        setTokenAPI(sessionStorage.getItem('BarenToken'));
    },[])
    return <Template atualizar = {false} cliente={props.location.state.cliente} >
        
       
        <div style ={{paddingLeft : '30px', paddingRight : '30px'}}>
            <div style={{backgroundColor:"#fff",paddingLeft : '10px', paddingTop : '5px', paddingBottom : '5px',marginTop : '10px', borderLeft: 'solid 4px #37474F'}}>
            <Grid container spacing = {2} style={{paddingBottom:'0px!important', paddingTop : '0px!important'}}>            
                <Grid item xs = {7}><Typography variant='h5'>Gestão de Relatórios</Typography></Grid>
                
            </Grid>
                
        </div>
        </div>
       <div className='div-form-container' style={{paddingLeft:'30px', paddingTop : '100px'}} >
        <Grid container spacing={1}>
        <Grid item xs={2}>
          <TextField 
          label ='Data Inicial'
          size='large'
          variant='outlined'
          type='date'
          value={dataInicial}
          onChange={(e)=>setDataInicial(e.target.value)}
          InputLabelProps={{shrink:true}}
          fullWidth
          />
        </Grid>

        <Grid item xs={2}>
          <TextField 
          label ='Data Final'
          size='large'
          variant='outlined'
          type='date'
          value={dataFinal}
          onChange={(e)=>setDataFinal(e.target.value)}
          InputLabelProps={{shrink:true}}
          fullWidth
          />
        </Grid>
        </Grid>
        <br/>
        <table>
         
          <tr>
            <td style={{width :'60px'}}><img src={IconePDF}  height={48}/></td>
            <td><a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataRelatoriosCobrancaCliente?action='+btoa("idempresa="+idempresa+"&idcliente="+idcliente+"&tipo=&dataInicial="+dataInicial)+"&razaoSocial="+razaosocial+"&codigo="+idcliente+"&oauth=9f5c30eab8c4644b5a8da4ddcb263c75"} target='_blank'>Relatório de equipamentos monitorados</a></td>
          </tr>
         
          <tr>
            <td style={{width :'60px'}}><img src={IconePDF}  height={48}/></td>
            <td><a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataRelatoriosContadoresAoVivo?action='+btoa("idempresa="+idempresa+"&idcliente="+idcliente+"&tipo=&dataInicial="+dataInicial)+"&razaoSocial="+razaosocial+"&codigo="+idcliente+"&oauth=9f5c30eab8c4644b5a8da4ddcb263c75"} target='_blank'>Relatório de Contadores Ao Vivo</a></td>
          </tr>
{/*
          <tr>
            <td style={{width :'60px'}}><img src={IconePDF}  height={48}/></td>
            <td><a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getDataRelatoriosCobrancaCliente?action='+btoa("idempresa="+idempresa+"&idcliente="+idcliente+"&tipo=&dataInicial="+dataInicial)+"&razaoSocial="+razaosocial+"&codigo="+idcliente+"&oauth=9f5c30eab8c4644b5a8da4ddcb263c75"} target='_blank'>Relatório Visão Geral de Parque</a></td>
          </tr>
*/
}
          <tr>
            <td style={{width :'60px'}}><img src={IconePDF}  height={48}/></td>
            <td><a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/getreportDcaCliente?action='+btoa("idempresa="+idempresa+"&idcliente="+idcliente+"&tipo=&dataInicial="+dataInicial)+"&razaoSocial="+razaosocial+"&codigo="+idcliente+"&oauth=9f5c30eab8c4644b5a8da4ddcb263c75"} target='_blank'>Relatório DCA´s instalados</a></td>
          </tr>

          <tr>
            <td style={{width :'60px'}}><img src={IconePDF}  height={48}/></td>
            <td><a href= {'https://datacount.com.br/webapi/index.php/relatoriosController/relatorioContadorDiarioGraficoPeriodo?id=datacount&action='+tokenAPI+'&idempresa='+idempresa+'&dataInicial='+dataInicial+'&dataFinal='+dataFinal+'&tipoData='+'&idcliente='+idcliente+'&tipo=html&razaoSocial='+razaosocial+"&oauth="+md5(idcliente+"#"+idempresa)} target='_blank'>Relatório de Contadores Diários</a></td>
          </tr>
        </table>
       
        </div>
    </Template>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(GestaoRelatoriosCliente)