import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip, Typography } from '@material-ui/core';

// Styled Components
const Container = styled.div`
  align-items: center;
  text-align : center;
  align-content:center;
`;


const TonerContainer = styled.div`
  display: flex;
  align-items: center;
  width : 100%;
  margin-top:5px;
 
`;

const TonerProgressNivel = styled.div`
  flex: 1;
  height: 100%;
  background-color: #d9d9d9;
  border-radius: 2px;
  position: relative;
`;

const TonerProgress = styled.div`
  width: ${(props) => (props.value >= 0 && props.value <= 100 ? props.value : 0)}%;
  height: 100%;
  background-color: ${(props) => props.color || '#000'};
  border-radius: 2px;
`;

const SiglaContainer = styled.div`
  font-size: 0.5vw;
  background-color: ${(props) => props.bgColor};
  border-radius: 3px;
  color: #fff;
  padding: 2px 5px;
  text-align: center;
  width : 60px;
`;

const DisplayAlertToner = ({ small, value, color, sigla, rotulo, data }) => {
  const renderSigla = (sigla) => {

    var textoSigla = "";
    switch (rotulo){
      case "PRETO":textoSigla = "K";
        break;

      case "CIANO":textoSigla = "C";
        break;

      case "MAGENTA":textoSigla = "M";
        break;

      case "AMARELO":textoSigla = "Y";
        break;
      
    }
    const colors = {
      C: '#0D47A1',
      M: '#D32F2F',
      Y: '#F57F17',
      K: '#000',
    };

    return sigla && <SiglaContainer bgColor={colors[textoSigla] || '#ccc'}>{value+'%'}</SiglaContainer>;
  };

  

  return<div style={{width : '100%', display:'flex', flexWrap:'wrap'}}>
          <div style={{fontSize : '9px', width : '100%'}}>{data}</div>
          <div style={{width: '100%',  textAlign: 'center', display: 'flex', justifyItems : 'center', paddingLeft : '25%'}}>
          <TonerContainer>
            {renderSigla("K")}       
          </TonerContainer>  
          </div>      
        </div>
};

// PropTypes para validação de props


export default DisplayAlertToner;
